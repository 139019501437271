.card {
  box-shadow: var(--box-shadow);
  margin: 20px;
  background: var(--background-light);
  cursor: pointer;
  text-align: center;
}
.adsImg img {
  height: 270px;
  width: 270px;
}
