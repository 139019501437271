body {
  margin: 0;
  background: var(--background);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'HelveticaNeue', 'Fira Sans', 'Droid Sans',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --background: #eceff1;
  --background-card: #fafafa;
  --background-grey: #f2f2f2;
  --background-dark: #b2b2b2;
  --background-light: #ffffff;
  --background-light-disabled: #f0f0f0;
  --background-light-active: #f5f5f5;
  --text-color: #000000;
  --text-color-disabled: #808080;
  --blue: #22b8da;
  --red: #cf4860;
  --yellow: #f4c54d;
  --cyan: #04e0d0;
  --green: #9ec06f;
  --orange: #d77a51;
  --pink: #e69dbb;
  --purple: #7a7acf;
  --grey: #cccccc;
  --lighten-blue: #90dbec;
  --lighten-red: #e7a3af;
  --lighten-yellow: #f9e2a6;
  --lighten-cyan: #81efe7;
  --lighten-green: #cde29e;
  --lighten-orange: #ebbca8;
  --lighten-pink: #f2cedd;
  --lighten-purple: #bcbce7;
  --darken-blue: #0094c7;
  --darken-red: #b70010;
  --darken-yellow: #eea800;
  --darken-cyan: #00d0b8;
  --darken-green: #6aa900;
  --darken-orange: #c33700;
  --darken-pink: #d96c99;
  --darken-purple: #3737b7;
  --payment-card-color: #e0efff;
  --box-shadow: 0px 3px 2px 0px #a1a1a1;
  --box-shadow-light: 0px 3px 2px 0px #e8e8e8;
  --box-shadow-inset: inset 0px 3px 2px 0px #a1a1a1;
}
