.ad {
  padding: 1rem;
  display: grid;
  grid-template-areas:
    'img title'
    'img date'
    'img desc'
    'img quantity'
    'img price'
    'img contact';
  grid-template-columns: 1fr 1fr;
  height: 100%;
  grid-gap: 10px;
  text-align: center;
  background: var(--background-light);
}

@media (max-width: 800px) {
  .ad {
    grid-template-areas:
      'img '
      'title'
      'date'
      'desc'
      'quantity'
      'contact';
    grid-template-columns: 1fr;
  }
}

.img {
  grid-area: img;
  object-fit: contain;
}

.img img {
  width: 100%;
}

.title {
  font-size: large;
  grid-area: title;
  text-transform: capitalize;
}

.date {
  grid-area: date;
}

.desc {
  grid-area: desc;
}

.quantity {
  grid-area: quantity;
}
.price {
  grid-area: price;
}

.contact {
  grid-area: contact;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.contact:hover {
  background: var(--background);
}
