.searchbar {
  background: var(--background-light);
  box-shadow: var(--box-shadow);
  margin: 0 6rem;
  padding: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  font-size: large;
}

.geo {
  cursor: pointer;
}

.categories select {
  padding: 0.25em 0.5em;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
  border: none;
  font-size: large;
  background: var(--grey);
  border-radius: 8px;
}
.categories select option {
  font-size: large;
}

@media (max-width: 800px) {
  .searchbar {
    margin: 0;
  }
}
