.layout {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  width: 100%;
}

.headbar {
  padding: 0 1rem;
  background: var(--green);
  height: 120px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.headbarTitle {
  font-size: larger;
  font-weight: bold;
  cursor: pointer;
}

.headbarActions {
  display: flex;
  flex-direction: row;
}

.action {
  font-weight: bold;
  padding: 20px;
  cursor: pointer;
}

.main {
}

.footer {
  background: var(--green);
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.footer a {
  font-size: small;
  text-decoration: none;
  color: var(--text-color);
}
