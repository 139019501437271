.container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.form {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background: var(--background-light);
  border-radius: 8px;
}

.input {
  padding: 20px;
  background: var(--background);
  border: none;
  border-radius: 8px;
  font-size: large;
  margin: 20px;
}

.button {
  padding: 20px;
  margin: 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  background: var(--background-light);
  font-size: large;
}

.button:hover {
  background: var(--background);
}

.button:active {
  background: var(--background-dark);
}

.actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.link {
  font-weight: bold;
  cursor: pointer;
}

.error {
  text-align: center;
  color: var(--red);
}
